



















































































import { Nullable } from "@/types";
import { Component } from "vue-property-decorator";
import { namespace } from "vuex-class";
const PlaceTypesStore = namespace("PlaceTypesStore");
const PlansStore = namespace("PlansStore");
import { PremiumModel } from "./models/PremiumModel";
import { PlanModel } from "@/core/models/PlanModel";
import PremiumService from "./PremiumService";

import Content from "@/layouts/components/Content.vue";
import BaseTitle from "@/components/base/BaseTitle.vue";
import BaseButton from "@/components/base/BaseButton.vue";
import BaseIcon from "@/components/base/BaseIcon.vue";
import Spinner from "@/components/Spinner.vue";
import PremiumRoom from "./components/PremiumRoom.vue";
import PremiumPlans from "./components/PremiumPlans.vue";
import PartnerForm from "./components/PartnerForm.vue";
import AddPremium from "./components/AddPremium.vue";
import PremiumLabel from "./components/PremiumLabel.vue";
import NoBillingPlanWarning from "@/modules/MyRoomsPage/components/NoBillingPlanWarning.vue";
import TopBanner from '@/components/banners/TopBanner.vue'

import { BreakpointsMixin } from "@/mixins/breakpoints.mixin";

@Component({
  name: "Premium",
  components: {
    Content,
    BaseTitle,
    BaseButton,
    BaseIcon,
    Spinner,
    PremiumRoom,
    PremiumPlans,
    AddPremium,
    PremiumLabel,
    NoBillingPlanWarning,
    PartnerForm,
    TopBanner
  },
  metaInfo: {
    title: "Премиум",
  },
})
export default class ProfilePage extends BreakpointsMixin {
  current: PremiumModel[] = [];
  loading = false;
  roomId: Nullable<number> = null;
  planId: Nullable<number> = null;
  showAddPremium = false;

  @PlaceTypesStore.Action
  private getPlaceTypes!: () => Promise<void>;

  @PlansStore.Action
  private getPlans!: () => Promise<void>;

  @PlansStore.Getter
  public planList!: PlanModel[];

  get isPremiumNotEmpty(): boolean {
    return this.current && !!this.current.length;
  }

  created(): void {
    this.loadData();
  }

  async loadData(): Promise<void> {
    this.loading = true;
    const [current] = await Promise.all([
      PremiumService.getCurrent(),
      this.getPlans(),
      this.getPlaceTypes(),
    ]);
    this.current = current;
    this.loading = false;
  }

  addPremium(roomId: Nullable<number> = null, planId: Nullable<number> = null): void {
    this.roomId = roomId;
    this.planId = planId;
    this.showAddPremium = true;
  }
}
